<template>
  <span>
    <input
      ref="assetUploader"
      id="assetUploader"
      type="file"
      @change="onChange"
    />

    <span @click="onClick">
      <slot />
    </span>
  </span>
</template>

<script>
import { importFile } from "@/api/finance/contract-assets";
export default {
  methods: {
    onClick() {
      this.$refs.assetUploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.$emit("setLoading", true);
      const hide = this.$message.loading("上传中...", 0);

      importFile(data)
        .then((res) => {
          console.log("upload res", res);
          this.$emit("refresh");
        })
        .finally(() => {
          this.$emit("setLoading", false);
          hide();
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#assetUploader {
  display: none;
}
</style>