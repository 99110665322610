import request from '../request'
import download from '../download'

export function fetchList(params) {
    return request({
        url: `/finance-service/contract/asset/queryList`,
        params,
    })
}

// 导入
export function importFile(data) {
    return request({
        url: '/finance-service/contract/asset/invoice/change/import',
        method: 'post',
        data,
    })
}

// 导出应收账款
export function downloadFile() {
    return download({
        url: '/finance-service/contract/asset/exportExcel',
        method: 'post',
    })
}

export function fetchDetail(params) {
    return request({
        url: '/finance-service/contract/asset/query/' + params.id,
    })
}

export function edit(data) {
    return request({
        url: '/finance-service/contract/asset/update',
        method: 'post',
        data,
    })
}

export function changeInvoice(data) {
    return request({
        url: '/finance-service/contract/asset/invoice/change',
        method: 'post',
        data,
    })
}

// 填报计划
export function addPlan(data) {
    return request({
        url: '/finance-service/contract/asset/fill',
        method: 'post',
        data,
    })
}

// 给清收计划添加动态
export function fill(data) {
    return request({
        url: `/finance-service/contract/asset/track/fill`,
        method: 'post',
        data,
    })
}

// 获取协作者列表
export function fetchHelperList({ id }) {
    return request({
        url: `/finance-service/contract/asset/getAssistUserList/${id}`
    })
}