import { debounce } from 'lodash'
export default {

    data() {
        return {
            scroll: {}
        }
    },

    mounted() {
        this.onResize();

        this.debouncedOnResize = debounce(this.onResize, 300)

        window.addEventListener('resize', this.debouncedOnResize)

        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener('resize', this.debouncedOnResize)
        });

    },



    methods: {
        onResize() {
            if (document.documentElement.clientWidth < 2000) {
                this.scroll = {
                    x: 2000,
                };
            } else {
                this.scroll = {};
            }

        }
    }
}