<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
        <a-row>
          <a-col :span="18">
            <a-form-model-item>
              <a-input placeholder="设计编号" v-model="form.designCode" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="工程名称" v-model="form.contractName" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="客户名称" v-model="form.partyAname" style="width: 120px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="填报状态" v-model="form.reportStatus" style="width: 150px">
                <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">{{ item.name
                                  }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <div class="right" style="margin-top: 4px">
              <a-space>
                <Uploader v-if="$getPermission($route.path + '/import')"
                  @setLoading="(payload) => (importLoading = payload)" @refresh="getList">
                  <a-button :loading="importLoading">调整发票批量导入</a-button>
                </Uploader>

                <a-button v-if="$getPermission($route.path + '/export')" @click="download" type="primary">导出</a-button>
              </a-space>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table bordered :data-source="list" :loading="loading" @change="onChange" :pagination="{
                      total,
                      current,
                      pageSize,
                      showTotal: (total) => `共 ${total} 条记录`,
                    }" :scroll="scroll">
        <a-table-column title="设计编号" data-index="designCode" align="center" width="100px">
        </a-table-column>
        <a-table-column title="工程名称" data-index="contractName" width="240px">
        </a-table-column>
        <a-table-column title="客户名称" data-index="partyAname" width="240px">
        </a-table-column>

        <a-table-column title="合同金额(元)" width="100px" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{
                          typeof text.contractAmountNj === "number"
                          ? text.contractAmountNj
                          : "--"
                          }}</span>
          </template>
        </a-table-column>

        <a-table-column title="项目类型" width="100px" data-index="projectTypeName">
        </a-table-column>
        <a-table-column title="项目状态" width="100px" data-index="statusName" align="center">
        </a-table-column>
        <a-table-column title="产值进度(%)" width="90px" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.projectProgress }}</span>
          </template>
        </a-table-column>

        <a-table-column title="合同资产(元)" width="100px" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{
                          typeof text.assetAmount === "number" ? text.assetAmount : "--"
                          }}</span>
          </template>
        </a-table-column>

        <a-table-column title="合同收款结算进度(%)" width="100px" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.receiptProgress }}</span>
          </template>
        </a-table-column>

        <a-table-column title="任务预测金额(元)" align="right" width="110px">
          <template slot-scope="text">
            <span style="color: #1890ff">{{
                          typeof text.taskInvoiceAmount === "number"
                          ? text.taskInvoiceAmount
                          : "--"
                          }}</span>
          </template>
        </a-table-column>

        <a-table-column title="任务预测时间" align="center" width="100px" data-index="taskInvoiceTime">
        </a-table-column>

        <!-- <a-table-column title="历史不能统计发票总额(万元)" align="right">
          <template slot-scope="text">
            <Money :money="text.historyInvoiceSum" />
          </template>
        </a-table-column> -->

        <a-table-column title="应开票金额(元)" width="100px" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{
                          typeof text.needInvoiceSum === "number"
                          ? text.needInvoiceSum
                          : "--"
                          }}</span>
          </template>
        </a-table-column>

        <a-table-column title="已开票金额(元)" width="100px" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{
                          typeof text.invoiceSum === "number" ? text.invoiceSum : "--"
                          }}</span>
          </template>
        </a-table-column>

        <a-table-column title="未开票金额(元)" width="100px" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff">{{
                          typeof text.notInvoiceSum === "number" ? text.notInvoiceSum : "--"
                          }}</span>
          </template>
        </a-table-column>

        <a-table-column title="生产负责人" data-index="productionMasterName" width="80px" align="center">
        </a-table-column>
        <a-table-column title="区域负责人" width="80px" data-index="areaMasterName" align="center">
        </a-table-column>

        <a-table-column title="填报状态" width="80px">
          <template slot-scope="text">
            <DataDictFinder dictType="contractAssetStatus" :dictValue="text.reportStatus" />
          </template>
        </a-table-column>

        <a-table-column align="center" width="60px" fixed="right" title="操作">
          <template slot-scope="text">
            <a href="#" v-if="$getPermission($route.path + '/detail')" @click.prevent="$router.push($route.path + '/detail?id=' + text.id)
                                      ">详情</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";
import Uploader from "./components/uploader.vue";
import { fetchList, downloadFile } from "@/api/finance/contract-assets";
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
export default {
  name: "contract-assets",
  mixins: [screen, watermark],

  components: {
    Uploader,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      importLoading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("account_collect_status");
    },
  },

  activated() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    // 导出
    download() {
      downloadFile(this.form).then((blob) => {
        saveAs(blob, "合同资产.xlsx");
      });
    },
  },
};
</script>